.antd-pro-ellipsis-ellipsis {
  display: inline-block;
  width: 100%;
  overflow: hidden;
  word-break: break-all;
}
.antd-pro-ellipsis-lines {
  position: relative;
}
.antd-pro-ellipsis-lines .antd-pro-ellipsis-shadow {
  position: absolute;
  z-index: -999;
  display: block;
  color: transparent;
  opacity: 0;
}
.antd-pro-ellipsis-lineClamp {
  position: relative;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
}
